<template>
    <div class="login">
        <div class="w-100 h-100 flex-c flex-jc-c flex-ai-c flex-nowrap">
            <div class="flex-r flex-jc-c flex-ai-c flex-nowrap w-px-max-900">
                <div class="w-px-min-300">
                    <div class="bg-white p-20 brd-px-20 h-px-400 m-b-20">
                        <div class="title-box">
                            <span>{{ mate_name }}</span>
                        </div>
                        <div>
                            <el-form ref="seniorform" :model="seniorform" :rules="seniorformrules">
                                <el-form-item prop="username">
                                    <el-input v-model="seniorform.username" placeholder="请输入用户名" clearable maxlength="40" @clear="clearInputEvent">
                                        <template #prepend
                                            ><el-icon style="color: #616e9d"><User /></el-icon
                                        ></template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="password">
                                    <el-input v-model="seniorform.password" type="password" autocomplete="off" placeholder="请输入密码" show-password clearable maxlength="40">
                                        <template #prepend
                                            ><el-icon style="color: #616e9d"><Lock /></el-icon
                                        ></template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-checkbox label="记住密码" name="remember" v-model="seniorform.remember"></el-checkbox>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" class="w-100" @click="myloginbutton('seniorform')">登录</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <aw-footer />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElLoading, ElMessageBox } from 'element-plus'
import { http, config, tool, dict } from '@/assets/js'

export default defineComponent({
    name: 'login',
    data() {
        return {
            seniorform: {
                username: '',
                password: '',
                remember: false
            },
            seniorformrules: {
                username: [
                    {
                        required: true,
                        message: '账号不能为空',
                        trigger: 'blur'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: '密码不能为空',
                        trigger: 'blur'
                    }
                ]
            },
            mate_name: dict.platform.mate_name
        }
    },
    methods: {
        // 点击回车-登录
        onkeydownEvent() {
            document.onkeydown = (e) => {
                e = window.event || e
                if (e.keyCode === 13) {
                    this.myloginbutton('seniorform')
                }
            }
        },
        // 点击清除按钮事件
        clearInputEvent() {
            this.seniorform = {
                username: '',
                password: '',
                remember: false
            }
        },
        //点击登录按钮
        myloginbutton(formName) {
            let forminfo = this.seniorform
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    //加载loading
                    var loading = ElLoading.service({
                        lock: true,
                        text: '正在登录...'
                    })
                    var username = forminfo.username
                    var password = forminfo.password

                    let loginData = this.loginEvent(username, password)
                    loginData.then((getdatas) => {
                        var token = getdatas[config.Authentication]
                        var userinfo = getdatas
                        this.setUserInfo() // 记住密码
                        localStorage.setItem('token', token) //存储token

                        this.menuEvent(token).then((menu_response) => {
                            localStorage.setItem('mymodels', JSON.stringify(menu_response)) //存储导航信息
                            localStorage.setItem('userinfo', JSON.stringify(userinfo)) //存储用户信息

                            // 跳转当前账号权限下的第一个页面路由
                            const all_id_path = tool.traverseChildren(this.$router.options.routes)
                            const last_one_child = this.treeLastOneData(menu_response)
                            const one_path = all_id_path.find((n) => n.id == last_one_child.id)['path']
                            this.$router.push({ path: one_path }).catch((error) => error)
                        })
                    })
                    setTimeout(() => {
                        loading.close()
                    }, 3000)
                } else {
                    return false
                }
            })
        },

        // 遍历对象获取第一个元素下child中的最后一级
        treeLastOneData(obj) {
            // 检查对象是否为空
            if (Object.keys(obj).length === 0) {
                return null
            }

            // 获取第一个属性的值
            const firstKey = Object.keys(obj)[0]
            const firstValue = obj[firstKey]

            // 如果该值没有child属性，说明已经是最后一级，直接返回
            if (!firstValue.hasOwnProperty('child')) {
                return firstValue
            }

            // 递归调用获取下一级
            return this.treeLastOneData(firstValue.child)
        },

        // 获取登录
        async loginEvent(username, password) {
            return await http.post(
                '/agent/Member/login',
                {
                    username: username,
                    password: password
                },
                { mSlogin: true }
            )
        },
        // 获取菜单
        async menuEvent(token) {
            return await http.get('/agent/Member/menu')
        },

        // 存储账户密码
        setUserInfo() {
            let Base64 = require('js-base64').Base64
            let username = Base64.encode(this.seniorform.username) //账号加密
            let password = Base64.encode(this.seniorform.password) //密码加密

            // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
            // 如果没有勾选，储存的信息为空
            // 设置7天保存密码
            if (this.seniorform.remember) {
                this.setCookie('dominate_v2', username, 7)
                this.setCookie('cypher_v2', password, 7)
                this.setCookie('username', '还想看密码', 1)
                this.setCookie('password', '没门儿', 1)
            } else {
                this.setCookie('dominate_v2', '')
                this.setCookie('cypher_v2', '')
                this.setCookie('username', '')
                this.setCookie('password', '')
            }
        },
        // 获取cookie
        getCookie(key) {
            if (document.cookie.length > 0) {
                var datas = document.cookie.split('; ')
                for (var i = 0; i < datas.length; i++) {
                    var value = datas[i].split('=')
                    if (value[0] === key) {
                        return value[1]
                    }
                }
            } else {
                return false
            }
        },
        // 保存cookie  - exdate.setTime(exdate.getTime() - 10);[10s清除]
        setCookie(cName, value, expiredays) {
            var exdate = new Date()
            exdate.setDate(exdate.getDate() + expiredays)
            document.cookie = cName + '=' + decodeURIComponent(value) + (expiredays == null ? '' : ';expires=' + exdate.toGMTString())
        }
    },
    created() {
        let Base64 = require('js-base64').Base64

        // 在页面加载时从cookie获取登录信息
        var username = this.getCookie('dominate_v2')
        var password = this.getCookie('cypher_v2')
        // 如果存在，赋值给表单，并且将记住密码勾选
        if (username && password) {
            this.seniorform.username = Base64.decode(username)
            this.seniorform.password = Base64.decode(password)
            this.seniorform.remember = true
        }

        // 点击回车-登录
        this.onkeydownEvent()

        document.title = this.mate_name
    }
})
</script>

<style scoped>
.login {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(rgb(111, 130, 255), rgb(56, 83, 255), rgb(1, 53, 176));
}

.title-box {
    color: rgb(1, 53, 176);
    font-weight: bolder;
    font-size: 30px;
    padding: 30px 0 40px;
}
</style>
